<template>
  <moe-page title="拼团订单">
    <!-- 筛选信息 -->
    <moe-inquire @search="groudOrderSearch">
      <el-form-item label="团单编号">
        <el-input
          :value="groudOrderParams.id"
          @input="(value) => groudOrderParams.id = value.replace(/^\.+|[^\d]/g, '')"
          placeholder="输入团单编号"
          maxlength="50"
          clearable />
      </el-form-item>
      <el-form-item label="活动名称">
        <el-input v-model.trim="groudOrderParams.name" placeholder="输入活动名称" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="团单状态" v-if="tabsIndex === 2">
        <moe-select type="groupOrderState" v-model="groudOrderParams.state" placeholder="选择团单状态" clearable></moe-select>
      </el-form-item>
      <el-form-item label="开团时间">
        <el-date-picker
          v-model="dateTime"
          clearable
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="(dateTime) => ([groudOrderParams.startTime, groudOrderParams.endTime] = dateTime || ['', ''])" />
      </el-form-item>
      <el-form-item label="团长姓名">
        <el-input v-model.trim="groudOrderParams.leaderName" placeholder="输入团长姓名" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="团长手机号">
        <el-input v-model.trim="groudOrderParams.leaderPhone" placeholder="输入团长手机号" maxlength="11" clearable />
      </el-form-item>
    </moe-inquire>

    <moe-tabs type="border-card"
      :tabsList="[{ label: '待返现列表', name: 'PENDING' }, { label: '已返现列表', name: 'FINISH' }, { label: '团单列表' }]"
      @change="handleChange">
      <template #0>
        <moe-table
          v-if="tabsIndex === 0"
          ref="groudOrderTable"
          url="/shop/group/order/list"
          :params="groudOrderParams"
          :number-show="false"
          :mode="mode">
          <el-table-column prop="id" label="团单编号" min-width="80" />
          <el-table-column prop="name" label="活动名称" min-width="80" :show-overflow-tooltip="true" />
          <el-table-column prop="goodsName" label="商品名称" min-width="80" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="leaderName" label="团长名称" min-width="80" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="leaderPhone" label="团长手机" min-width="80" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="开团时间" min-width="150">
            <template slot-scope="{ row }">
              {{ $moe_time.getTimeStamp(row.startTime) }}
            </template>
          </el-table-column>
          <el-table-column label="拼团状态" min-width="80">
            <div :class="$moe_format.getGroupOrderStateColor(row.state)" slot-scope="{ row }">
              {{ $moe_format.getGroupOrderState(row.state) }}
            </div>
          </el-table-column>
          <el-table-column label="成团人数" min-width="100">
            <template slot-scope="{ row }">
              {{ `${row.num}人成团` }}
            </template>
          </el-table-column>
          <el-table-column prop="payNum" label="已支付人数" min-width="100" />
          <el-table-column label="团长返现" min-width="120">
            <template slot-scope="{ row }">
              {{ $moe_math.mathGreaterThan(row.cashbackAmount, 0) ? `¥ ${row.cashbackAmount}` : '-' }}
            </template>
          </el-table-column>

          <el-table-column label="返现状态">
            <template slot-scope="{ row }">
              {{ $moe_format.getGroupCashbackState(row.cashbackState) }}
            </template>
          </el-table-column>

          <el-table-column label="操作" width="250" fixed="right">
            <div class="moe-table__btns" slot-scope="{ row }">
              <el-button type="success" size="mini" icon="el-icon-document" @click="getGroupOrderItemList(row)">查看订单</el-button>
              <el-button v-if="['PENDING'].includes(row.cashbackState)" type="primary" size="mini" icon="el-icon-thumb" @click="groupOrderCashback(row.id)">确认返现</el-button>
            </div>
          </el-table-column>
        </moe-table>
      </template>
      <template #1>
        <moe-table v-if="tabsIndex === 1" ref="groudOrderTable" url="/shop/group/order/list" :params="groudOrderParams"
          :number-show="false" :mode="mode">
          <el-table-column prop="id" label="团单编号" min-width="80" />
          <el-table-column prop="name" label="活动名称" min-width="80" :show-overflow-tooltip="true" />
          <el-table-column prop="goodsName" label="商品名称" min-width="80" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="leaderName" label="团长名称" min-width="80" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="leaderPhone" label="团长手机" min-width="80" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="开团时间" min-width="150">
            <template slot-scope="{ row }">
              {{ $moe_time.getTimeStamp(row.startTime) }}
            </template>
          </el-table-column>
          <el-table-column label="拼团状态" min-width="80">
            <div :class="$moe_format.getGroupOrderStateColor(row.state)" slot-scope="{ row }">
              {{ $moe_format.getGroupOrderState(row.state) }}
            </div>
          </el-table-column>
          <el-table-column label="成团人数" min-width="100">
            <template slot-scope="{ row }">
              {{ `${row.num}人成团` }}
            </template>
          </el-table-column>
          <el-table-column prop="payNum" label="已支付人数" min-width="100" />
          <el-table-column label="团长返现" min-width="120">
            <template slot-scope="{ row }">
              {{ $moe_math.mathGreaterThan(row.cashbackAmount, 0) ? `¥ ${row.cashbackAmount}` : '-' }}
            </template>
          </el-table-column>

          <el-table-column label="返现状态">
            <template slot-scope="{ row }">
              {{ $moe_format.getGroupCashbackState(row.cashbackState) }}
            </template>
          </el-table-column>

          <el-table-column label="操作" width="250" fixed="right">
            <div class="moe-table__btns" slot-scope="{ row }">
              <el-button type="success" size="mini" icon="el-icon-document" @click="getGroupOrderItemList(row)">查看订单</el-button>
            </div>
          </el-table-column>
        </moe-table>
      </template>
      <template #2>
        <moe-table
          v-if="tabsIndex === 2"
          ref="groudOrderTable"
          url="/shop/group/order/list"
          :params="groudOrderParams"
          :number-show="false"
          :mode="mode">
          <el-table-column prop="id" label="团单编号" min-width="80" />
          <el-table-column prop="name" label="活动名称" min-width="80" :show-overflow-tooltip="true" />
          <el-table-column prop="goodsName" label="商品名称" min-width="80" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="leaderName" label="团长名称" min-width="80" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="leaderPhone" label="团长手机" min-width="80" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="开团时间" min-width="150">
            <template slot-scope="{ row }">
              {{ $moe_time.getTimeStamp(row.startTime) }}
            </template>
          </el-table-column>
          <el-table-column label="拼团状态" min-width="80">
            <div :class="$moe_format.getGroupOrderStateColor(row.state)" slot-scope="{ row }">
              {{ $moe_format.getGroupOrderState(row.state) }}
            </div>
          </el-table-column>
          <el-table-column label="成团人数" min-width="100">
            <template slot-scope="{ row }">
              {{ `${row.num}人成团` }}
            </template>
          </el-table-column>
          <el-table-column prop="payNum" label="已支付人数" min-width="100" />
          <el-table-column label="团长返现" min-width="120">
            <template slot-scope="{ row }">
              {{ $moe_math.mathGreaterThan(row.cashbackAmount, 0) ? `¥ ${row.cashbackAmount}` : '-' }}
            </template>
          </el-table-column>

          <el-table-column label="返现状态">
            <template slot-scope="{ row }">
              {{ $moe_format.getGroupCashbackState(row.cashbackState) }}
            </template>
          </el-table-column>

          <el-table-column label="操作" width="250" fixed="right">
            <div class="moe-table__btns" slot-scope="{ row }">
              <el-button type="success" size="mini" icon="el-icon-document" @click="getGroupOrderItemList(row)">查看订单</el-button>
              <el-button v-if="['PENDING'].includes(row.cashbackState)" type="primary" size="mini" icon="el-icon-thumb" @click="groupOrderCashback(row.id)">确认返现</el-button>
            </div>
          </el-table-column>
        </moe-table>
      </template>
    </moe-tabs>

    <moe-dialog :show="dialogForm.show" title="团单订单" width="70%" @close="handleDialogClose()">
      <div>
        <div v-if="['GOING'].includes(dialogForm.state)">拼团进行中，当前还差{{ dialogForm.num - dialogForm.payNum }}单成团，剩余时间{{ dialogForm.leftTime }}</div>

        <div style="height: 500px;min-height: 500px;" class="df fdc">
          <moe-table :numberShow="false" :data="dialogForm.list" :mode="false" :params="{ pageNum: 1 }">
            <el-table-column label="订单编号" prop="id" />
            <el-table-column label="团单号" prop="groupOrderId" />
            <el-table-column label="活动名称" prop="name" min-width="150" :show-overflow-tooltip="true" />
            <el-table-column label="商品名称" prop="goodsName" min-width="150" :show-overflow-tooltip="true" />
            <el-table-column label="用户手机" prop="userPhone" min-width="100" :show-overflow-tooltip="true" />
            <el-table-column label="用户姓名" prop="userName" min-width="100" :show-overflow-tooltip="true" />
            <el-table-column label="角色">
              <template slot-scope="{ row }">
                {{ row.isLeader ? '团长' : '团员' }}
              </template>
            </el-table-column>
            <el-table-column label="支付时间" min-width="150">
              <template slot-scope="{ row }">
                {{ $moe_time.getTimeStamp(row.payTime) }}
              </template>
            </el-table-column>
            <el-table-column label="订单金额" min-width="120">
              <template slot-scope="{ row }">
                {{ $moe_math.mathGreaterThan(row.money, 0) ? `¥ ${row.money}` : '-' }}
              </template>
            </el-table-column>
            <el-table-column label="订单状态" min-width="150">
              <template slot-scope="{ row }">
                {{ $moe_format.getPayStatus(row.payStatus) }}
              </template>
            </el-table-column>
          </moe-table>
        </div>
      </div>
      <template slot="footer">
        <el-button @click="handleDialogClose()">关闭</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'OrderGroupList',
  data() {
    return {
      groudOrderParams: {
        pageNum: 1,
        pageSize: 10,
        id: '',
        name: '',
        state: '',
        startTime: '',
        endTime: '',
        leaderName: '',
        leaderPhone: '',
      },
      dateTime: [],
      mode: false,
      tabsIndex: 0,
      cashbackStateOptions: {
        0: 'PENDING',
        1: 'FINISH',
        2: ''
      },
      dialogForm: {
        show: false,
        state: '',
        num: 0,
        payNum: 0,
        leftTime: 0,
        list: [],
      },
    }
  },
  methods: {
    groudOrderSearch(isSearch) {
      this.mode = true;
      if (!isSearch) {
        this.groudOrderParams = {
          pageNum: 1,
          pageSize: 10,
          id: '',
          name: '',
          state: '',
          startTime: '',
          endTime: '',
          leaderName: '',
          leaderPhone: '',
          cashbackState: this.cashbackStateOptions[this.tabsIndex],
        };

        this.dateTime = [];
      }

      this.$refs['groudOrderTable'].searchData();
    },
    handleChange({ index }) {
      this.mode = true;
      this.tabsIndex = Number(index);
      this.groudOrderParams.cashbackState = this.cashbackStateOptions[index];
      this.groudOrderParams.pageNum = 1;
      this.groudOrderParams.pageSize = 10;

      if (this.tabsIndex !== 2) {
        this.groudOrderParams.state = '';
      }
    },
    /** 团单子订单列表 */
    getGroupOrderItemList({ id, state, num, payNum, leftTime }) {
      this.dialogForm.state = state;
      this.dialogForm.num = num;
      this.dialogForm.payNum = payNum;
      if (leftTime) {
        this.dialogForm.leftTime = this.$moe_time.countDown(Date.now() + leftTime * 1000, '', 'minutes');
        let interTime = 60 * 1000;
        this.countdownInterval = setInterval(() => {
          this.dialogForm.leftTime = this.$moe_time.countDown(Date.now() + leftTime * 1000 - interTime, '', 'minutes');
        }, interTime);
      }
      this.$moe_api.GROUPACTIVITY_API.getGroupOrderItemList({ groupOrderId: id }).then((data) => {
        if (data.code === 200) {
          this.dialogForm.list = data.result.list;
          this.dialogForm.show = true;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    handleDialogClose() {
      this.dialogForm.show = false;
    },
    /** 确认返现 */
    groupOrderCashback(groupOrderId) {
      this.$moe_layer.confirm(`您确定同意"确认返现"？`, () => {
        this.$moe_api.GROUPACTIVITY_API.groupOrderCashback({
          groupOrderId
        }).then((data) => {
          if (data.code === 200) {
            this.dialogForm.show = false;
            this.groudOrderSearch(false);
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
  },
  mounted() {
    this.groudOrderSearch(false);
  }
}
</script>

<style lang="scss"></style>